@import '../variable.scss';

.rank.content-wrapper {
  width: 512px;
  margin: 0 auto;
  padding: 140px 0 380px;
  flex-direction: column;
  @media only screen and (max-width: $mobile_max) {
    min-width: 0;
    width: 100%;
    padding: 48px 24px;
  }
  .title {
    @include font12;
    line-height: 16px;
    color: $color-k25;
    text-align: left;
  }
  .header {
    @include center;
    h2 {
      @include font27;
      @include light;
      line-height: 24px;
      margin: 0 0 12px;
    }
    h1 {
      position: relative;
      @include font36;
      line-height: 36px;
      margin: 0;
      svg {
        position: absolute;
        margin-left: 4px;
      }
    }
    @media only screen and (max-width: $mobile_max) {
      h2 {
        @include font24;
      }
      h1 {
        @include font27;
      }
    }
    &.searched {
      @include left;
      width: 100%;
      padding-left: 15px;
      display: flex;
      h2,
      h1 {
        @include font18;
        line-height: 24px;
      }
      h2 {
        margin: 0;
        margin-right: 4px;
      }
    }
  }

  .search-input {
    width: 100%;
    height: 48px;
    margin: 40px 0 0;
    background: $color-white;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    &.searched {
      margin-top: 16px;
      margin-bottom: 0px;
    }
    i {
      @include font18;
      color: $color-k25;
    }
    input {
      padding-left: 10px;
      flex: 1;
      @include font13;
      border: none;
      outline: none;
    }
  }
  .alert {
    @include left;
    @include font10;
    @include bold;
    color: #f24662;
    width: 100%;
    padding-left: 15px;
    margin: 8px 0 48px;
    &.searched {
      @include light;
      @include right;
      color: $color-k05;
      margin: 8px 0 24px;
    }
  }
  .search-result {
    width: 464px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-white;
    padding: 24px;
    @media only screen and (max-width: $mobile_max) {
      min-width: 0;
      width: 100%;
      padding: 24px 0;
    }
    .header {
      margin-bottom: 46px;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: $mobile_max) {
        margin-bottom: 30px;
      }
      h3 {
        @include font21;
        @include bold;
        line-height: 24px;
        color: $color-k05;
      }
      .photo {
        border-radius: 20px;
        margin-right: 16px;
      }
    }
    .body {
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: $mobile_max) {
        flex-direction: column;
      }
      .banner {
        @include center;
        position: relative;
        margin-right: 32px;
        @media only screen and (max-width: $mobile_max) {
          margin-right: 0;
        }
        img {
          width: 170px;
        }
        .banner-body {
          position: absolute;
          color: $color-white;
          top: 38px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .display-name {
            font-size: 8px;
            line-height: 12px;
            margin-bottom: 8px;
          }
          .percentage-title {
            @include font10;
            @include bold;
            line-height: 12px;
            margin-bottom: 4px;
          }
          .percentage-number {
            @include font24;
            @include bold;
            line-height: 20px;
          }
        }
      }
      .fields {
        width: 100%;
        padding-top: 8px;
        @media only screen and (max-width: $mobile_max) {
          padding-top: 14px;
          padding-left: 76px;
        }
        .field {
          margin-bottom: 16px;
          h4 {
            @include font18;
            line-height: 20px;
          }
        }
      }
    }
    button {
      background-color: $color-white;
      align-self: flex-end;
      cursor: pointer;
      border: none;
      outline: none;
      padding: 0;
      font-size: 12px;
      color: $color-k25;
      margin-top: 30px;
      margin-bottom: 46px;
      @media only screen and (max-width: $mobile_max) {
        margin-top: 14px;
      }
      .mdi {
        font-size: 16px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
  section {
    @include font12;
    line-height: 16px;
    color: $color-k25;
    .icon {
      display: inline;
      position: relative;
      .mdi {
        @include font14;
        margin-left: 3px;
        color: $color-secondary1;
      }
      p {
        z-index: 1000;
        transition: opacity 0.2s ease;
        opacity: 0;
        position: absolute;
        background-color: rgba(58, 58, 58, 0.7);
        color: $color-white;
        border-radius: 12px;
        top: 0;
        left: 22px;
        width: 320px;
        padding: 8px;
        @media only screen and (max-width: $mobile_max) {
          position: fixed;
          top: 50%;
          left: 50%;
          margin-left: -140px;
        }
      }
      &:hover p {
        opacity: 1;
      }
    }
  }
  .history {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    margin-right: auto;
    .items {
      display: flex;
      width: 100%;
      font-size: 14px;
      @media only screen and (max-width: $mobile_max) {
        flex-direction: column;
      }
      .item {
        width: 176px;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: $mobile_max) {
          width: calc(100vw - 24px);
          padding-right: 24px;
        }
        .content {
          text-align: center;
          color: #4463bb;
          font-size: 27px;
          line-height: 40px;
          margin: 8px 0px;
        }
      }
    }
    .chart-container {
      margin: 40px 0px;
      width: 100%;
      font-size: 14px;
      .chart {
        display: flex;
        max-width: 526px;
        @media only screen and (max-width: $mobile_max) {
          flex-direction: column;
        }
        .item {
          width: 176px;
          @media only screen and (max-width: $mobile_max) {
            width: calc(100vw - 24px);
            padding-right: 24px;
          }
          .rank {
            text-align: center;
            color: #bd1328;
            font-size: 27px;
            line-height: 40px;
            margin: 8px 0px;
          }
          .caption {
            text-align: center;
            color: #808080;
            font-size: 11px;
            line-height: 16px;
          }
        }
        .line-chart {
          position: absolute;
          top: 0;
          left: 0;
          width: 350px;
          height: 140px;
          @media only screen and (max-width: $mobile_max) {
            width: calc(100vw - 24px);
            padding-right: 24px;
          }
        }
        .blur {
          filter: blur(4px);
        }
        .blur-container {
          width: 350px;
          height: 140px;
          @media only screen and (max-width: $mobile_max) {
            width: calc(100vw - 24px);
            padding-right: 24px;
          }
          position: relative;
          img {
            width: 100%;
          }
          .blur-top {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 350px;
            height: 140px;
            margin-top: auto;
            z-index: 9;
            .text {
              display: block;
              font-weight: bold;
              @include font13;
            }
            button {
              border: 1px solid #bd1328;
              box-sizing: border-box;
              border-radius: 24px;
              background-color: $color-white;
              width: 120px;
              height: 42px;
              margin-top: 12px;
              margin-bottom: 48px;
              @include font13;
            }
            button a {
              color: #bd1328;
            }
          }
        }
      }
    }
  }
  .exposure {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title {
      font-size: 14px;
      line-height: 16px;
      color: #3a3a3a;
    }
    .categories {
      margin-top: 32px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      @media only screen and (max-width: $mobile_max) {
        margin-top: 38px;
        margin-bottom: 32px;
        flex-direction: column;
        align-items: center;
      }
      .chart-container {
        .donut-chart {
          .donut-piece {
            fill: transparent;
            stroke-width: 20;
          }
        }
      }
      .legend-container {
        margin-left: 44px;
        width: 184px;
        @media only screen and (max-width: $mobile_max) {
          margin-top: 28px;
          margin-left: 0;
        }
        .capsule {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .chip {
          width: 10px;
          height: 10px;
          margin-right: 8px;
        }
        .legend {
          @include font13;
          line-height: 24px;
          color: $color-k0;
        }
      }
    }
    .keywords {
      padding-top: 12px;
      margin-bottom: 48px;
      width: 100%;
      text-align: center;
      table {
        border-collapse: collapse;
      }
      tr {
        margin-top: 8px;
      }
      th,
      td {
        text-align: left;
        padding: 4px 0;
        span {
          color: #757575;
        }
        button {
          @include font12;
          color: $color-k2;
        }
      }
      td {
        color: #484848;
        @include font14;
      }
      th:first-child,
      td:first-child {
        width: 320px;
      }
      th:nth-child(2),
      td:nth-child(2) {
        min-width: 84px;
      }
      th:nth-child(3),
      td:nth-child(3) {
        min-width: 60px;
      }
      button {
        background-color: $color-white;
        border: none;
        outline: none;
        padding: 0;
        @include font14;
        color: $color-k1;
      }
    }
    .blur-container {
      width: 520px;
      height: 260px;
      @media only screen and (max-width: $mobile_max) {
        width: calc(100vw - 24px);
        height: 180px;
        padding-right: 24px;
      }
      position: relative;
      img {
        width: 100%;
        position: absolute;
      }
      .blur-top {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 350px;
        height: 140px;
        margin-top: 40px;
        z-index: 9;
        .text {
          display: block;
          font-weight: bold;
          @include font13;
        }
        button {
          border: 1px solid #bd1328;
          box-sizing: border-box;
          border-radius: 24px;
          background-color: $color-white;
          width: 120px;
          height: 42px;
          margin-top: 12px;
          margin-bottom: 48px;
          @include font13;
        }
        button a {
          color: #bd1328;
        }
      }
    }
  }
  .info {
    @include font13;
    @include light;
    @include center;
    .update {
      margin-top: 6px;
      @include light;
      @include font13;
      line-height: 24px;
      margin-top: 48px;
    }
    button {
      border: 1px solid #bd1328;
      box-sizing: border-box;
      border-radius: 24px;
      background-color: $color-white;
      width: 210px;
      height: 48px;
      margin-top: 12px;
      margin-bottom: 48px;
      @include font13;
    }
    button a {
      color: #bd1328;
    }
  }
  .underline {
    border-bottom: 1px solid #f5f5f5;
  }
}
