$color-k0: #000;
$color-k05: #3a3a3a;
$color-k1: #484848;
$color-k2: #757575;
$color-k25: #808080;
$color-k3: #c2d2d2;
$color-grey: #faf8f9;
$color-red: #d0021b;
$color-yellow: #cec014;
$color-primary0: #ff5a5f;
$color-primary1: #bd1328;
$color-secondary1: #4463bb;
$color-white: #fff;

$mobile_max: 768px;

@mixin font10 {
  font-size: 10px;
}
@mixin font12 {
  font-size: 12px;
}
@mixin font13 {
  font-size: 13px;
}
@mixin font14 {
  font-size: 14px;
}
@mixin font16 {
  font-size: 16px;
}
@mixin font18 {
  font-size: 18px;
}
@mixin font21 {
  font-size: 21px;
}
@mixin font24 {
  font-size: 24px;
}
@mixin font27 {
  font-size: 27px;
}
@mixin font36 {
  font-size: 36px;
}
@mixin bold {
  font-weight: 700;
}
@mixin normal {
  font-weight: 400;
}
@mixin light {
  font-weight: 300;
}
@mixin left {
  text-align: left;
}
@mixin center {
  text-align: center;
}
@mixin right {
  text-align: right;
}
