@import '../variable.scss';

.banner.flex {
  padding: 110px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.full {
    height: 100vh;
  }
  .flex:nth-child(2) {
    padding-bottom: 100px;
  }
  .title {
    @include center;
    @include font24;
    @include normal;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    &::after {
      margin-top: 40px;
      content: '';
      display: block;
      width: 90px;
      height: 2px;
      background-color: $color-k3;
    }
  }
  a {
    @include font13;
    width: 180px;
    height: 48px;
    border-radius: 24px;
    padding: 0 12px 0 20px;
    border: 1px solid $color-primary1;
    color: $color-primary1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    &.inversed {
      border: 1px solid $color-primary1;
      background-color: $color-primary1;
      color: $color-white;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  @media only screen and (max-width: $mobile_max) {
    padding: 80px 0 103px;
    .title {
      @include font18;
      padding-bottom: 25px;
      &::after {
        margin-top: 30px;
      }
    }
    .flex:nth-child(2n) {
      flex-direction: column;
      a {
        margin-right: 0px;
        margin-bottom: 16px;
      }
    }
  }
}
