@import './variable.scss';

.pad40 {
  padding: 0 40px 0 40px;
}

a,
a:active,
a:hover,
a:visited {
  color: $color-k0;
  text-decoration: none;
  -webkit-transition-duration: 0.2s; /* Safari */
  transition-duration: 0.2s;
}
a:active,
a:hover {
  color: $color-k2;
}

.content-wrapper {
  width: 960px;
  margin: 0 auto;
  @media only screen and (max-width: $mobile_max) {
    min-width: 0;
    width: 100%;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop_br {
  display: block;
  @media only screen and (max-width: $mobile_max) {
    display: inline-block;
  }
}

.desktop_only {
  @media only screen and (max-width: $mobile_max) {
    display: none;
  }
}

.mobile_br {
  display: inline-block;
  @media only screen and (max-width: $mobile_max) {
    display: block;
  }
}

.main-header {
  height: 69px;
  display: flex;
  justify-content: center;
  img {
    height: 26px;
  }
  @media only screen and (max-width: $mobile_max) {
    .content-wrapper.flex {
      padding: 0 16px;
    }
  }
  nav {
    a {
      @include font13;
      @include bold;
      margin-left: 48px;
      sup {
        color: #f24662;
      }
      @media only screen and (max-width: $mobile_max) {
        margin-left: 16px;
      }
    }
  }
}
.main-footer {
  border-top: 1px solid #c0c0c0;
  padding: 20px 0 15px;
  font-size: 14px;
  .content-wrapper.flex {
    margin-bottom: 16px;
    &.left {
      justify-content: flex-start;
      div {
        padding-right: 24px;
        margin-bottom: 8px;
        span {
          @include bold;
          padding-right: 6px;
        }
      }
    }
    h4 {
      img {
        vertical-align: middle;
        margin-right: 18px;
        width: 144px;
      }
    }
    .flex {
      h4 {
        text-align: center;
        &:first-child {
          padding-right: 24px;
          border-right: 1px solid $color-k0;
        }
        &:last-child {
          padding-left: 24px;
        }
      }
    }
    @media only screen and (max-width: $mobile_max) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 16px;
      margin: 0;
      h4 {
        margin-bottom: 24px;
        img {
          display: block;
          margin-bottom: 12px;
        }
      }
      .flex {
        h4 {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.help-contents {
  padding: 80px 0;
  @media (max-width: 768px) {
    padding: 28px 0;
  }
}

.invisible {
  display: none;
}
